<template>
  <div class="mx-0 px-0">
    <v-form @submit.prevent="submitComment">
      <v-textarea
        v-model="comment"
        outlined
        single-line
        auto-grow
        rows="1"
        no-resize
        placeholder="Leave a comment"
        :append-icon="icons.mdiSend"
        @click:append="submitComment"
      />
    </v-form>
  </div>
</template>

<script>
import { mdiSend } from '@mdi/js';
export default {
  data() {
    return {
      comment: '',
      icons:{
        mdiSend: mdiSend
      }
    };
  },
  methods: {
    submitComment() {
      this.$emit('comment-submitted', this.comment); // Emitting the comment
      this.comment = ''; // Optionally clear the comment after submit
    }
  }
};
</script>

<style scoped>
/* You might need to add custom styles here */
</style>
