import Devices from '../../services/api/Devices';
import Vue from "vue";
const state = {
  description: null,
  tags: null,
  deviceComments: {},
  peripheralComments: {},
  noDetectdevices: false,
  onlineStatus: null,
  total_devices: null,
  filledLocations: [],
  links: null,
  pages_number: null,
  devices: [],
  flyouts: {
    editDevice: false,
    addDeviceRunner: false,
    deviceInfo: false,
  },
  activePeripheral: {
    type: "",
    index: undefined,
    device: ""//string
  },
  loaders:{
    rssh: false,
    screenshot: false,
    rd: false
  },
  deviceAvailableOrientations: [
    {
      text: 'Normal',
      value: 'normal',
    },
    {
      text: '90°',
      value: '90',
    },
    {
      text: '180°',
      value: '180',
    },
    {
      text: '270°',
      value: '270',
    },
    {
      text: 'Flipped',
      value: 'flipped',
    },
    {
      text: '90° flipped',
      value: '90 flipped',
    },
    {
      text: '180° flipped',
      value: '180 flipped',
    },
    {
      text: '270° flipped',
      value: '270 flipped',
    }
  ],

  selectedCamera: {},
  selectedDevice: '',
  selectedDeviceType: '',
  categories: [],
  types: [],
  device_types: {},
  deviceScreenshotUrl: '',
  gettingCameras: false,
  filterCheck: {
    ordering: '-online',
    search: '',
    location_uuid: [],
    device_type_uuid: [],
    connected_status: [],
    online: [],
    enabled: [],
    tag_uuid: [],
    page_size: null,
    page: null
  },
  sort: "",
  sortAscending: [
    {
      text:'Online/Offline',
      value: '-online',
    },
    {
      text:'Time Added',
      value: '-created_at'
    },    
    {
      text:'Time Connected',
      value: '-installation_date',
    }, 
    {
      text:'Time Modified',
      value:'-updated_at'
    },
  ],

  sortDescending: 
  [
    {
      text:'Online/Offline',
      value: 'online',
    },
    {
      text:'Time Added',
      value: 'created_at'
    },    
    {
      text:'Time Connected',
      value: 'installation_date',
    }, 
    {
      text:'Time Modified',
      value:'updated_at'
    },
    ]
};
const getters = {
 deviceCommentsGetter() {
      return state.deviceComments;
  },
  peripheralCommentsGetter(){
    return state.peripheralComments;
  },
  loaderScreenshot(){
    return state.loaders.screenshot;
  },
  loaderRssh(){
    return state.loaders.rssh;
  },
  loaderRd(){
    return state.loaders.rd;
  },
  currentPage: state => state.filterCheck.page,
  getDescriptionDetect(state){
    return state.description;
  },
  getTagsDetect(state){
    return state.tags;
  },
  getOnlineStatus(state) {
    return state.onlineStatus;
  },
  getTotaldevices(state){
    return state.total_devices;
  },
  getFilledLocations(state){
    return state.filledLocations;
  },
  getLinks(state){
    return state.links;
  },
  getTotalPages(state){
    return state.pages_number;
  },
  getNonDevices(state){
    return state.noDetectdevices;
  },
  getSort(state) {
    return state.sort;
  },
  getSortAscending(state) {
    return state.sortAscending;
  },
  getSortDescending(state) {
    return state.sortDescending;
  },
  getCheckLocations(){
    return state.filterCheck.location_uuid;
  },
  getCheckDeviceCategory(){
    return state.filterCheck.device_type_uuid;
  },
  getCheckConnectedStatus(){
    return state.filterCheck.connected_status;
  },
  getCheckActivityStatus(){
    return state.filterCheck.online;
  },
  getCheckDeviceStatus(){
    return state.filterCheck.enabled;
  },
  getCheckDeviceTags(){
    return state.filterCheck.tag_uuid;
  },
  activePeripheral(state) {
    let device = state.devices.find(c => c.uuid === state.activePeripheral.device);
    if (device) {
      return state.activePeripheral.type === "misc" ? device.peripherals[state.activePeripheral.index] : device.displays[state.activePeripheral.index];
    } else {
      return null;
    }
  },
  getFilterCheck(){
    return state.filterCheck;
  },
  getDevices(state){
    return state.devices;
  },
  getSelectedDevice(state) {
    return state.devices.find(c => c.uuid === state.selectedDevice);
    //trazi po stringu odnopsno uuid
  },
  getSelectedCamera(state) {
    return state.selectedCamera;
  },
  getAvailableOrientations(state) {
    return state.deviceAvailableOrientations
  },
  getFlyoutEditDevice(state) {
    return state.flyouts.editDevice;
  },
  getDeviceScreenshot(state) {
    return state.deviceScreenshotUrl;
  },
  categories(state) {
    return state.categories;
  },
  types(state) {
    return state.types;
  }
};

const generateDisplayConfig = (device) => {
  let deviceDisplays = Object.assign(device.displays)
  deviceDisplays.forEach((display) => {
    display.display_uuid = display.uuid;
    display.applications.forEach(app => {
      app.config = typeof app.config === 'object' ? JSON.stringify(app.config) : app.config;
    });
  });
  return deviceDisplays;
}
const actions = {
    setSort({commit}, data){
      commit('SET_SORT', data);
    },
    setOrdering({commit}, data){
      commit('SET_ORDERING', data);
    },
    setSearch({commit}, data){
      commit('SET_SEARCH', data);
    },
    setFilterCheck({commit}, data) {
    commit('SET_CHECK_FILTER', data);
    },
    setCheckLocations({commit}, data) {
      commit('SET_CHECK_LOCATIONS', data);
    },
    setCheckDeviceCategory({commit}, data) {
      commit('SET_CHECK_DEVICE_CATEGORY', data);
    },
    setCheckConnectedStatus({commit}, data) {
      commit('SET_CHECK_CONNECTED_STATUS', data);
    },
    setCheckActivityStatus({commit}, data) {
      commit('SET_CHECK_ACTIVITY_STATUS', data);
    },
    setCheckDeviceStatus({commit}, data) {
      commit('SET_CHECK_DEVICE_STATUS', data);
    },
    setCheckDeviceTags({commit}, data) {
      commit('SET_CHECK_DEVICE_TAGS', data);
    },
    updateDisplayResolution({commit}, data) {
      commit('UPDATE_DISPLAY_RESOLUTION', data);
    },
    updateDisplayRefreshRate({commit}, data) {
      commit('UPDATE_DISPLAY_REFRESH_RATE', data);
    },
    updateDisplayOrientation({commit}, data) {
      commit('UPDATE_DISPLAY_ORIENTATION', data);
    },
    togglePeripheralStatus({commit}, {device_uuid, type, peripheral_uuid, status}) {
      Devices.togglePeripheralStatus(device_uuid, type, peripheral_uuid, status)
        .then(() => {
          commit('TOGGLE_PERIPHERAL_DEVICE_STATUS', {device_uuid, peripheral_uuid, status});
        })
        .catch(error => {
          console.log(error);
        });
    },
    setActivePeripheral({commit}, val) {
      commit('SET_ACTIVE_PERIPHERAL', val);
    },
    saveDisplayConfiguration({dispatch, state}, uuid) {
      let device = state.devices.find(d => d.uuid === uuid);
      if (!device.displays) {
        dispatch('global/addAlert', {
          type: 'error',
          message: `Failed to save configuration for device ${device.name}. No displays detected.`
        }, {root: true});
      } else {
        let config = generateDisplayConfig(device);
        Devices.saveDisplays(uuid, {
          displays_configuration: config
        }).then(() => {
          dispatch('global/addAlert', {
            type: 'success',
            message: `Display configuration for device ${device.name} successfully saved.`
          }, {root: true});
        }).catch(error => {
          console.log(error);
          dispatch('global/addAlert', {
            type: 'error',
            message: `Failed to save configuration for device ${device.name}. Check console.`
          }, {root: true});
        })
      }
    },
    getDeviceDisplays({dispatch, commit}, uuid) {
      Devices.getDisplays(uuid).then(response => {
          let displays = response.data.displays;
          // Update resolutions
          displays.forEach((display, i) => {
            displays[i].availableResolutions = [];
            displays[i].availableRefreshRates = {};
            display.available_resolutions.forEach(resolution => {
              display.availableResolutions.push(Object.keys(resolution)[0]);
              display.availableRefreshRates[Object.keys(resolution)[0]] = [];
              resolution[Object.keys(resolution)[0]].forEach((rr) => {
                let rrObj = {
                  text: `${parseFloat(rr).toFixed(2)} Hz`,
                  value: rr,
                };
                display.availableRefreshRates[Object.keys(resolution)[0]].push(rrObj);
              });
            });
            if (!display.resolution) {
              display.resolution = display.availableResolutions[0];
            }
            if (!display.refresh_rate) {
              display.refresh_rate = display.availableRefreshRates[display.resolution][0].value;
            }
            if (displays.length > 1) {
              display.primaryChangeable = true;
            }
            displays[i] = display;
          })
          // End update resolutions
          commit('SET_DEVICE_DISPLAYS', {
            uuid: uuid,
            payload: displays
          });
        }
      ).catch((err) => {
        console.log(err);
        dispatch('global/addAlert', {
          type: 'error',
          message: 'Failed to fetch displays.'
        }, {root: true});
        

      });
    },
    updateAppConfig({commit}, data) {
      commit('UPDATE_APPLICATION_FOR_DISPLAY', data);
    },
    toggleApplicationForDisplay({commit}, data) {
      commit('SET_APPLICATION_FOR_DISPLAY', data);
    },
    configurePrimaryDisplay({commit}, data) {
      if (data.value === true) {
        commit('SET_PRIMARY_DISPLAY', data);
      } else {
        commit('UNSET_PRIMARY_DISPLAY', data);
      }
    },
    configureDisableDisplay({commit}, data) {
      if (data.value === true) {
        commit('DISABLE_DISPLAY', data);
      } else {
        commit('ENABLE_DISPLAY', data);
      }
    },
    getAvailableApplications({dispatch, commit}, uuid) {
      Devices.getAvailableApps(uuid).then(response => {
        commit('SET_DEVICE_AVAILABLE_APPLICATIONS', {
          uuid: uuid,
          payload: response.data.applications
        });
      }).catch((err) => {
        
        console.log(err.response.data);
        dispatch('global/addAlert', {
          type: 'error',
          message: `Failed to fetch applications for device ${uuid}.`
        }, {root: true});
      });
    },
    getDevices({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        Devices.get(state.sort)
          .then(response => {
            let devices = response.data.devices;
            let links = response.data.links;
            let total_devices = response.data.objects_count;
            let view = rootState.view;
            let total_pages = response.data.pages_count;
            commit('SET_TOTAL_DEVICES', total_devices);
            commit('SET_TOTAL_PAGES', total_pages);
            if (state.filterCheck.page > 1 && view === 'grid') {
              devices = state.devices.concat(devices);
            }
            // Filter out unique devices based on device.uuid
            const uniqueDevices = Array.from(new Set(devices.map(device => device.uuid)))
              .map(uuid => devices.find(device => device.uuid === uuid));
            commit('SET_DEVICES', uniqueDevices);
            commit('SET_LINKS', links);
            resolve();
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      });
    },    
    setFilledLocations({commit}){
      return new Promise(() => {
        Devices.getLocationContainDevices().then((response) =>{
          let data = response.data.locations;
          console.log(data)
          commit("SET_LOCATION_CONTAIN_DEVICES", data);
        })
      })
    },    
    getDeviceTypes({commit}) {
      return new Promise((resolve) => {
        Devices.getTypes().then(response => {
          commit('SET_DEVICE_TYPES', response.data);
          resolve();

        });
      });

    }
    ,
    getDeviceCategories({commit}) {
      return new Promise((resolve) => {
        Devices.getCategories().then(response => {
          commit('SET_DEVICE_CATEGORIES', response.data);
          resolve();
        });
      });
    }
    ,
    getDeviceFromUUID(uuid) {
      const device = state.devices.find((device) => device.uuid === uuid)
      const index = state.devices.findIndex((device) => device.uuid === uuid)
      return {device, index}
    },
    getUsedDeviceTypes({ commit }) {
      return new Promise((resolve, reject) => {
        Devices.getDevicesTypes()
          .then(response => {
            commit("SET_USED_DEVICE_TYPES", response.data);
            resolve(response.data);  // Resolve with the response data
          })
          .catch(error => {
            reject(error);  // Reject in case of an error
          });
      });
    },    
    getSpecificDevice({commit}, data) {
      const {route, uuid} = data;
      return new Promise((resolve) => {
        Devices.getDevice(route, uuid).then(response => {   
          console.log(response.data)
          commit('UPDATE_DEVICE_IN_STATE', response.data);
          commit('SET_SELECTED_DEVICE', uuid);
          resolve();
        });
      });
    }
    ,
// getCameraByUUID({ commit }, uuid) {
//     return new Promise((resolve) => {
//         Devices.getCameras(uuid).then(response => {
//             commit('SET_SELECTED_CAMERA', response.data);
//             resolve();
//         })
//     })
// },
    removeSelectedDevice({commit}, data){
      return commit('devices/SET_SELECTED_DEVICE', data);
    },
    setDeviceFlyout({commit}, data) {
      return commit('SET_FLYOUT', data);
    }
    ,
    setDeviceScreenshot({commit}, data) {
      return commit('SET_SCREENSHOT', data);
    }
    ,
    addRunnerDevice({commit}, data) {
      return new Promise((resolve) => {
        Devices.add(data).then(response => {
          commit('PUSH_DEVICE', response.data);
          resolve(response.data);
        });
      });
    }
    ,
    getCameras({commit}, uuid) {
      return new Promise((resolve) => {
        Devices.getCameras(uuid).then(response => {
          resolve(response.data);
          commit('GETTING_CAMERAS', true);
        })
      })
    }
    ,
    addCamera({commit}, data) {
      return new Promise((resolve, reject) => {
        Devices.addCamera(data).then(response => {
          commit('PUSH_DEVICE', response.data);
          resolve(response.data);
        }).catch(err => {
          reject(err);
        });
      })
    },
    addDevice({commit}, location_uuid, uuid, name, description, tags) {
      return new Promise((resolve, reject) => {
        Devices.installDevice(location_uuid, uuid, name, description, tags).then(response => {
          commit('PUSH_DEVICE', response.data);
          resolve(response.data);
        }).catch(err => {
          reject(err);
        });
      })
    }
    ,
    refreshDevice({commit}, data) {
      return new Promise((resolve) => {
       
        Devices.getDevice(data.type || 'device', data.uuid).then((response) => {
          commit('REPLACE_DEVICE', response.data);
          
          resolve();
        });
      });
    }
    ,
    updateDevice({commit, dispatch}, data) {
      return new Promise((resolve) => {
        const {device, index} = actions.getDeviceFromUUID(data.uuid)
        commit('UPDATE_DEVICE', {
          index: index,
          fields: data.fields
        });
        if (data.uuid === state.selectedDevice.uuid) {
          dispatch('getSpecificDevice', {
            route: state.selectedDeviceType,
            uuid: data.uuid
          })
          
        }
        
        resolve(device);
      })
    },
    deleteDevice({ commit }, data) {
      return new Promise((resolve, reject) => {
        Devices.deleteDevice(data.space_uuid, data.uuid)
          .then(() => {
            commit('DELETE_DEVICE', data.uuid);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    archiveDevice({commit}, data) {
      return new Promise((resolve, reject) => {
       
        Devices.archiveDevice(data.space_uuid, data.uuid).then(response => {
          commit('UPDATE_DEVICE_BY_UUID', response.data);
          commit('SET_SELECTED_DEVICE', '');
          
          resolve(response);
        }).catch(err => {
          
          reject(err);
        });
      })
    },
    unarchiveDevice({commit}, data) {
      return new Promise((resolve, reject) => {
        Devices.unarchiveDevice(data.space_uuid, data.uuid).then(response => {
          commit('UPDATE_DEVICE_BY_UUID', response.data);
          commit('SET_SELECTED_DEVICE', '');
          resolve(response);
        }).catch(err => {
          reject(err);
        });
      })
    },
    terminateConnection({commit}, data) {
      return new Promise((resolve, reject) => {
        Devices.terminateConnection(data.space_uuid, data.uuid).then(response => {
          commit('UPDATE_DEVICE_BY_UUID', response.data);
          resolve(response);
        }).catch(err => {
          reject(err);
        });
      })
    },
    // comments actions 
  async getCommentDevices({ commit }, device_uuid) {
    try {
      const response = await Devices.getCommentDevices(device_uuid);
      console.log(response)
      commit('SET_DEVICE_COMMENTS', response.data.comments );
    } catch (error) {
      console.error('Failed to fetch device comments:', error);
    }
  },
  async addCommentDevice({ commit }, device_uuid, account_uuid, data) {
    try {
      const response = await Devices.addCommentDevice(device_uuid, account_uuid, data);
      commit('ADD_DEVICE_COMMENT', { device_uuid, comment: response.data });
    } catch (error) {
      console.error('Failed to add device comment:', error);
    }
  },
  async editCommentDevice({ commit, getters }, { device_uuid, comment_uuid, data }) {
    try {
      const space_uuid = getters['global/getSpace'];
      const response = await Devices.editCommentDevice(space_uuid, device_uuid, comment_uuid, data);
      commit('EDIT_DEVICE_COMMENT', { device_uuid, comment_uuid, comment: response.data });
    } catch (error) {
      console.error('Failed to edit device comment:', error);
    }
  },

  async deleteCommentDevice({ commit, getters }, { device_uuid, comment_uuid }) {
    try {
      const space_uuid = getters['global/getSpace'];
      await Devices.deleteCommentDevice(space_uuid, device_uuid, comment_uuid);
      commit('DELETE_DEVICE_COMMENT', { device_uuid, comment_uuid });
    } catch (error) {
      console.error('Failed to delete device comment:', error);
    }
  },
  };
const mutations = {
  SET_LOADER_SCREENSHOT(state, data){
    state.loaders.screenshot = data;
  },
  SET_LOADER_RSSH(state, data){
    state.loaders.rssh = data;
  },
  SET_LOADER_RD(state, data){
    state.loaders.rd = data;
  },
  SET_DETECTION_TAGS(state, data){
    state.tags = data;
  },
  SET_DETECTION_DESCRIPTION(state, data){
    state.description = data;
  },
  SET_SORT(state, data) {
    state.sort = data;
    localStorage.setItem("checkSort", JSON.stringify(data));
  },
  SET_LINKS(state, data){
    state.links = data;
  },
  SET_SEARCH(state, data){
    state.filterCheck.search = data;
  },
  SET_NO_DETECT_DEVICES(state, payload){
    state.noDetectdevices = payload;
  },
  SET_ORDERING(state, data) {
    state.filterCheck.ordering = data;
  },
  SET_CHECK_FILTER(state, payload) {
    state.filterCheck = payload;
  },
  SET_CHECK_LOCATIONS(state, payload) {
    state.filterCheck.location_uuid = payload;
    localStorage.setItem("checkLocations", JSON.stringify(payload));
  },
  SET_CHECK_DEVICE_CATEGORY(state, payload) {
    //localStorage.setItem("checkDeviceCategory", JSON.stringify(payload));
    state.filterCheck.device_type_uuid = payload;
  },
  SET_CHECK_CONNECTED_STATUS(state, payload) {
    localStorage.setItem("checkConnectedStatus", JSON.stringify(payload));
    state.filterCheck.connected_status = payload;
  },
  SET_CHECK_ACTIVITY_STATUS(state, payload) {
    localStorage.setItem("checkActivityStatus", JSON.stringify(payload));
    state.filterCheck.online = payload;
  },
  SET_CHECK_DEVICE_STATUS(state, payload) {
    localStorage.setItem("checkDeviceStatus", JSON.stringify(payload));
    state.filterCheck.enabled = payload;
  },
  SET_CHECK_DEVICE_TAGS(state, payload) {
    localStorage.setItem("checkDeviceTags", JSON.stringify(payload));
    state.filterCheck.tag_uuid = payload;
  },
  SET_PAGE_NUMBER(state, payload){
    state.filterCheck.page = payload;
  },
  SET_DEVICES_NUMBER(state, payload){
    state.filterCheck.page_size = payload;
  },
  SET_TOTAL_PAGES(state, payload){
    state.pages_number = payload;
  },
  SET_DEVICES(state, payload) {
    if (!state.selectedDevice) {
      state.devices = payload;
    } else {
      let currentDevice = state.devices.filter(device => device.uuid === state.selectedDevice)[0];
      state.devices = payload.filter(device => device.uuid !== state.selectedDevice);
      state.devices.push(currentDevice);
    }
  },
  SET_LOCATION_CONTAIN_DEVICES(state, data){
    state.filledLocations = data;
  },
  UPDATE_DISPLAY_RESOLUTION(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'resolution', data.resolution);
        Vue.set(state.devices[device].displays[displayIndex], 'refresh_rate', state.devices[device].displays[displayIndex].availableRefreshRates[data.resolution][0].value);
      }
    }
  },
  UPDATE_DISPLAY_REFRESH_RATE(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'refresh_rate', data.refresh_rate);
      }
    }
  },
  UPDATE_DISPLAY_ORIENTATION(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'orientation', data.orientation);
      }
    }
  },
  UPDATE_APPLICATION_FOR_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        let applicationIndex = state.devices[device].displays[displayIndex].applications.findIndex(a => a.uuid === data.appUuid);
        Vue.set(state.devices[device].displays[displayIndex].applications[applicationIndex], 'config', data.config);
      }
    }
  },
  ENABLE_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'disabled', false);
      }
    }
  },
  DISABLE_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'disabled', true);
        if (state.devices[device].displays[displayIndex].primary) {
          Vue.set(state.devices[device].displays[displayIndex], 'primary', false);
          let otherDisplay = state.devices[device].displays.findIndex(d => d.uuid !== data.displayUuid);
          Vue.set(state.devices[device].displays[otherDisplay], 'primary', true);
        }
      }
    }
  },
  SET_PRIMARY_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'primary', true);
        // make other displays non primary
        let otherPrimaryDisplay = state.devices[device].displays.findIndex(d => d.uuid !== data.displayUuid && d.primary);
        Vue.set(state.devices[device].displays[otherPrimaryDisplay], 'primary', false);
      }
    }
  },
  UNSET_PRIMARY_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        Vue.set(state.devices[device].displays[displayIndex], 'primary', false);
        // set next display as primary
        let otherDisplay = state.devices[device].displays.findIndex(d => d.uuid !== data.displayUuid);
        Vue.set(state.devices[device].displays[otherDisplay], 'primary', true);
      }
    }
  },
  SET_APPLICATION_FOR_DISPLAY(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.deviceUuid);
    if (!state.devices[device].displays) {
      // no displays
      return false;
    } else {
      let displayIndex = state.devices[device].displays.findIndex(d => d.uuid === data.displayUuid);
      if (displayIndex === -1) {
        // display not found
        return false;
      } else {
        if (!state.devices[device].displays[displayIndex].applications) {
          // create new array
          Vue.set(state.devices[device].displays[displayIndex], 'applications', []);
        }
        // has display, update app toggle
        let appIndex = state.devices[device].displays[displayIndex].applications.findIndex(a => a.uuid === data.appUuid);
        if (appIndex === -1) {
          // no app, push

          // copy from available
          let application = state.devices[device].available_applications.find(b => b.uuid === data.appUuid);
          if (!application) {
            return false;
          }
          let newAppConfig = {};
          if (application.config && application.config.configurable_options) {
            // create new config
            application.config.configurable_options.forEach(option => {
              newAppConfig[option.deterministic_id] = option.default_value;
            });
          }
          // add new app
          Vue.set(state.devices[device].displays[displayIndex], 'applications', [...state.devices[device].displays[displayIndex].applications, {
            uuid: data.appUuid,
            name: application.name,
            config: newAppConfig
          }]);
        } else {
          // get all apps except intended and replace the array
          let apps = state.devices[device].displays[displayIndex].applications.filter(a => a.uuid !== data.appUuid);
          Vue.set(state.devices[device].displays[displayIndex], 'applications', apps);
        }
      }
    }
  },
  SET_DEVICE_DISPLAYS(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.uuid);
    Vue.set(state.devices[device], 'displays', data.payload);
    // check for CodeConnect app and add config to it
    state.devices[device].displays.forEach((display, i) => {
      display.applications.forEach((app, j) => {
        if (!('config' in app)) {
          Vue.set(state.devices[device].displays[i].applications[j], 'config', "{}");
        }
      })
    })
  },
  SET_DEVICE_AVAILABLE_APPLICATIONS(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.uuid);
    Vue.set(state.devices[device], 'available_applications', data.payload);
  },
  GETTING_CAMERAS(state, payload) {
    state.gettingCameras = payload;
  },
  PUSH_DEVICE(state, payload) {
    state.devices.push(payload);
  },
  SET_SELECTED_DEVICE(state, uuid) {
    state.selectedDevice = uuid;
    let device = state.devices.findIndex(c => c.uuid === uuid);
    if (state.devices[device] && state.devices[device].tags) {
      state.devices[device].tag_uuids = [];
      state.devices[device].tags.forEach(tag => {
        state.devices[device].tag_uuids.push(tag.uuid);
      });
    }
  },
  CLEAR_DEVICE_STATE(state){
    state.devices = [];
  },
  TOGGLE_PERIPHERAL_DEVICE_STATUS(state, data) {
    let device = state.devices.findIndex(c => c.uuid === data.device_uuid);
    let peripheral = state.devices[device].peripherals.findIndex(a => a.uuid === data.peripheral_uuid)
    Vue.set(state.devices[device].peripherals[peripheral], 'enabled', data.status);
  },
  SET_SELECTED_CAMERA(state, payload) {
    state.selectedCamera = payload;
  },
  SET_ACTIVE_PERIPHERAL(state, payload) {
    state.activePeripheral = payload;
  },
  SET_FLYOUT(state, data) {
    state.flyouts = data;
  },
  SET_DEVICE_CATEGORIES(state, data) {
    state.categories = data;
  },
  SET_DEVICE_TYPES(state, data) {
    state.types = data;
  },
  SET_SCREENSHOT(state, payload) {
    state.deviceScreenshotUrl = payload;
  },
  UPDATE_DEVICE(state, payload) {
    console.log(payload)
    payload.fields.forEach((field) => {
      state.devices[payload.index][field.key] = field.value
      Vue.set(state.devices, payload.index, state.devices[payload.index]);//confirming data changes  
    });
  },
  UPDATE_DEVICE_UUID(state, payload) {
    let index = state.devices.findIndex(c => c.uuid === payload.uuid);
    for (const [key, value] of Object.entries(payload.data) ){//iterating trough fields from api
      state.devices[index][key] = value;
    }
    Vue.set(state.devices, index, state.devices[index]);//confirming data changes
  },
  UPDATE_DEVICE_BY_UUID(state, payload) {
    let index = state.devices.findIndex(c => c.uuid === payload.uuid);
    for (const [key, value] of Object.entries(payload) ){//iterating trough fields from api
      state.devices[index][key] = value;
    }
    Vue.set(state.devices, index, state.devices[index]);//confirming data changes
  },
  UPDATE_DEVICE_IN_STATE(state, payload) {
    let device = state.devices.findIndex(c => c.uuid === payload.uuid);
    if (device === -1) {
      state.devices.push(payload);
    } else {
      state.devices[device] = payload;
    }
  },
  REPLACE_DEVICE(state, payload) {
    let filtered = state.devices.filter(c => c.uuid !== payload.uuid);
    state.devices = [...filtered, payload];
  },
  DELETE_DEVICE(state, deviceUUID) {
    const index = state.devices.findIndex(device => device.uuid === deviceUUID);
    if (index !== -1) {
      state.devices.splice(index, 1);
      // Optional: You can also reset the selectedDevice if necessary
      if (state.selectedDevice === deviceUUID) {
        state.selectedDevice = null;
      }
    }
  },
  UPDATE_ONLINE_STATUS(state, payload) {
    state.onlineStatus = payload;
  },
  SET_TOTAL_DEVICES(state, payload){
    state.total_devices = payload;
  },
  SET_USED_DEVICE_TYPES(state, payload){
    state.device_types = payload;
  },
  //mutations for comments 
    SET_DEVICE_COMMENTS(state, payload) {
      state.deviceComments = payload;
    },
  
    // Adds a new comment to a device
    ADD_DEVICE_COMMENT(state, { device_uuid, comment }) {
      if (state.deviceComments[device_uuid]) {
        state.deviceComments[device_uuid].push(comment);
      } else {
        Vue.set(state.deviceComments, device_uuid, [comment]);
      }
    },
  
    // Edits an existing comment on a device
    EDIT_DEVICE_COMMENT(state, { device_uuid, comment_uuid, comment }) {
      if (state.deviceComments[device_uuid]) {
        const index = state.deviceComments[device_uuid].findIndex(c => c.uuid === comment_uuid);
        if (index !== -1) {
          Vue.set(state.deviceComments[device_uuid], index, comment);
        }
      }
    },
  
    // Delete's a comment from a device
    DELETE_DEVICE_COMMENT(state, { device_uuid, comment_uuid }) {
      if (state.deviceComments[device_uuid]) {
        const newComments = state.deviceComments[device_uuid].filter(comment => comment.uuid !== comment_uuid);
        Vue.set(state.deviceComments, device_uuid, newComments);
      }
    },
    // Get comments for peripherals
    SET_PERIPHERAL_COMMENTS(state, payload){
      state.peripheralComments = payload;
    }  
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
