import { render, staticRenderFns } from "./CommentBox.vue?vue&type=template&id=535d1b1a&scoped=true"
import script from "./CommentBox.vue?vue&type=script&lang=js"
export * from "./CommentBox.vue?vue&type=script&lang=js"
import style0 from "./CommentBox.vue?vue&type=style&index=0&id=535d1b1a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535d1b1a",
  null
  
)

export default component.exports