import axios from "axios";
import router from "@/router";
import { TokenStorage } from "@/services/TokenStorage";
import IAM from "@/services/api/IAM.js";
import store from "@/store";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env["VUE_APP_API_URL"];
const LOGIN_ROUTE = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/device-management/protocol/openid-connect/token`;

axiosInstance.interceptors.request.use((config) => {
  store.commit("global/SET_LOADING", true);
  config.headers = { ...config.headers, ...TokenStorage.getAuthentication().headers };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    store.commit("global/SET_LOADING", false);
    return response;
  },
  (error) => {
    store.commit("global/SET_LOADING", false);
    console.log("Interceptor error:", error.response?.data); // Debugging log for error details

    if (!error.response) {
      return Promise.reject(error); // Network error or no response
    }

    const { status, data } = error.response;

    if (status === 400 && data.message === "action expired") {
      store.commit("SET_MESSAGE_TYPE", "LinkExpired");
      router.push({ name: "Success" });
      return Promise.reject(error);
    }
    if (status === 400) {
      return Promise.reject(error);
    }
    if (status === 409) {
      store.commit("SET_LOCATION_DISPLAY_ID", data.message);
      return Promise.reject(error);
    }
    if (status === 400 && data.message === "invalid token") {
      store.commit("SET_MESSAGE_TYPE", "LinkExpired");
      router.push({ name: "Success" });
      return Promise.reject(error);
    }
    if (status === 400 && data.error_description === "Account is not fully set up") {
      const userEmail = store.getters.getUserEmail; // Replace with actual getter name
      return IAM.passwordUpdate(userEmail)
        .then(() => {
          store.commit("SET_MESSAGE_TYPE", "AccountNotFullySetUp");
          router.push({ name: "Success" });
          return Promise.reject(error);
        });
    }
    if (status === 400 && data.email?.[0] === "Enter a valid email address.") {
      store.commit("SET_MESSAGE_TYPE", "EmailError");
      return Promise.reject(error);
    }
    if (status === 404 && data.message === "space-account not found") {
      store.commit("SET_MESSAGE_TYPE", "UserNotAllowed");
      router.push({ name: "Success" });
      return Promise.reject(error);
    }
    if (status === 405) {
      if (data.message === "Not allowed. Reset password request has been already sent. Please check your e-mail inbox.") {
        store.commit("SET_MESSAGE_TYPE", "MethodNotAllowed");
        router.push({ name: "Success" });
        return Promise.reject(error);
      } else if (data.message === "Not allowed for non-active accounts") {
        store.commit("SET_MESSAGE_TYPE", "UserNonExist");
        return Promise.reject(error);
      }
    }
    if (status === 503) {
      if (data.message === "This location has no available devices") {
        store.commit("devices/SET_NO_DETECT_DEVICES", true);
        store.commit("locations/SET_DETECTED_DEVICES", []);
      } else if (data.message === "physical device temporarily unavailable") {
        store.dispatch("global/addAlert", { type: "error", message: data.message }, { root: true });
      }
      return Promise.reject(error);
    }
    if (status === 422) {
      TokenStorage.clear();
      router.push({ name: "Login" });
      return Promise.reject(error);
    }
    if (status === 401) {
      if (error.config.url === LOGIN_ROUTE) {
        TokenStorage.clear();
        router.push({ name: "Login" });
        return Promise.reject(error);
      }

      return TokenStorage.getNewAccessToken()
        .then((token) => {
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${token}`;
          return axiosInstance.request(config);
        })
        .catch((err) => {
          TokenStorage.clear();
          router.push({ name: "Login" });
          return Promise.reject(err);
        });
    }

    // Default case to ensure unhandled errors are propagated
    return Promise.reject(error);
  }
);

export default axiosInstance;
