<template>
  <v-row class="fill-height">
    <v-col
        cols="12"
        md="4"
        class="pa-4 content-height overflow-y-auto">
      <v-list dense>
        <v-list-item-group
            mandatory
            v-model="item"
        >
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              active-class="text-secondary"
              @click="handleItemClick(item)"
          >
            <v-list-item-content>
              <v-list-item-title
                  class="text-secondary"
                  >{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-divider
        class="d-none d-md-flex"
        vertical></v-divider>
    <v-col
        cols="12"
        md="8"
        class="ml-n1 pl-4 pt-2 content-height overflow-y-auto"
        v-if="item === 0">
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Name</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Category</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.device_category_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Category</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.device_type_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Connection Status</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.connected_status === 'connected' ? 'Connected' : 'Disconnected'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Activity status</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <v-chip
              label
              small
              :color="device.online ? 'success' : 'error'">{{ device.online ? 'Online' : 'Offline' }}
          </v-chip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Device Status</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.enabled ? 'Enabled' : 'Disabled' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-show="this.$props.device.connected_status !== 'non_connected'" class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">
            {{device.online ? $t('assets.devices.deviceInfo.info.onlineStreakLabel'):
                $t('assets.devices.deviceInfo.info.offlineStreakLabel')}}
          </span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{
               onlineStreak 
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Last Online</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.online ? 'Online Now' : beautifyDate(device.last_seen)
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Space</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.space_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Location</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.location_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Added On</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.created_at) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Added By</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">TBA</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Connected On</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{
              device.installed_at ? beautifyDate(device.installed_at) : 'Unknown'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">UUID</span>
        </v-col>
        <v-col
            cols="12"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.uuid }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 1"
    >
      <v-row class="py-1"
      >
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Operating System</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{ device.operating_system_name || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">OS Version</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.operating_system_version || 'Unknown'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">OS Architecture</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.operating_system_architecture || 'Unknown'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Motherboard</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              `${device.motherboard_manufacturer} ${device.motherboard_product_name}` || 'Unknown'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">CPU</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.cpu_model || 'Unknown' }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <div
          v-for="(item, i) in device.graphic_cards" :key="i"
      >
        <v-row class="py-1">
          <v-col
              cols="12"
              sm="3"
              class="py-1">
            <span class="text-secondary font-weight-regular subtitle-2">Graphic Card</span>
          </v-col>
          <v-col
              cols="4"
              sm="9"
              class="py-1">
            <span class="subtitle-2 text-primary font-weight-regular">{{ device.graphic_cards[i].model }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <div
          v-for="(item, i) in device.storage_drives" :key="i"
      >
        <v-row class="py-1"
        >
          <v-col
              cols="12"
              sm="3"
              class="py-1">
            <span class="text-secondary font-weight-regular subtitle-2">{{ device.storage_drives[i].name }}</span>
          </v-col>
          <v-col
              cols="4"
              sm="6"
              class="py-1">
            <span class="subtitle-2 text-primary font-weight-regular">{{ device.storage_drives[i].model }}</span>
          </v-col>
          <v-col
              cols="4"
              sm="3"
              class="py-1">
            <span class="subtitle-2 text-primary font-weight-regular">{{ device.storage_drives[i].capacity }}B</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">RAM</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.ram_size ? Math.ceil((parseInt(device.ram_size, 10) / 1024)) + ' GB' : 'Unknown'
            }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Local IP Address</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.local_ip_addresses.join(', ') }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">External IP Address</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.external_ip_address }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">MAC Address</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span
              class="subtitle-2 text-primary font-weight-regular">{{
              device.mac_addresses.join(', ').toUpperCase()
            }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 2">
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation Date</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.installation_date) }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation UUID</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.installation_image_uuid }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Installation Name</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.installation_image_name }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Salt Minion ID</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ device.salt_minion_id }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="3"
            class="py-1">
          <span class="text-secondary font-weight-regular subtitle-2">Last Updated</span>
        </v-col>
        <v-col
            cols="4"
            sm="9"
            class="py-1">
          <span class="subtitle-2 text-primary font-weight-regular">{{ beautifyDate(device.updated_at) }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
        cols="12"
        sm="12"
        md="8"
        class="ml-n1 pl-4 pt-2"
        v-if="item === 3">
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Port Configurator</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Paylink Control</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Local Pub/Sub v2</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Seven Money Service</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Disabled</span>
          <v-icon color="#ec4234" class="ml-2">{{icons.mdiCancel}}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Local Stream Producer</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">PoS Printing Service</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Low paper level</span>
          <v-icon color="#ffba13" class="ml-2">{{ $vuetify.icons.values.alertCircle }}</v-icon>
          <v-icon class="ml-2">{{ $vuetify.icons.values.dotsVertical }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Linux Log Collector</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Not Responding</span>
          <v-icon color="#ec4234" class="ml-2">{{ $vuetify.icons.values.minusCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Service Discovery</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-1">
        <v-col
            cols="12"
            sm="6"
            class="py-1">
          <span class="text-primary font-weight-regular subtitle-2">Device Display Manager</span>
        </v-col>
        <v-col
            cols="4"
            sm="6"
            class="d-flex justify-end align-center py-1">
          <span class="subtitle-2 text-secondary font-weight-regular">Healthy</span>
          <v-icon color="#00a86b" class="ml-2">{{ icons.mdiCheckboxMarkedCircle }}</v-icon>
        </v-col>
      </v-row>
    </v-col>
    <v-col
          cols="12"
          sm="12"
          md="8"
          class="ml-n1 pl-4 pt-2"
          v-if="item === 4">
         <MonitorDetailView/>
    </v-col>
  </v-row>
</template>

<script>
// TODO: Dodati GPU, Storage
import {format} from "date-fns"
import {mdiCheckboxMarkedCircle, mdiCancel} from "@mdi/js"
import MonitorDetailView from '../../Monitoring/MonitorDetailView.vue'
import Monitoring from "@/services/api/Monitoring";

export default {
  components: {
      MonitorDetailView
    },
  props: ['device'],
  mounted() {
    this.calculateStreak();
    setInterval(() => {
      this.calculateStreak()
    }, 1000);
    this.changeItemData();
    if(this.$route.name === 'monitoring'){
      this.getLatestTelemetry();
    }
  },
  metaInfo() {
    return {
      title: `Asset Info - ${this.$props.device.name}` || "",
    }
  },
  methods: {
    beautifyDate(date) {
      return date ? format(new Date(date), 'dd MMM. yyyy HH:mm:ss') : "";
    },
    calculateStreak() {
      this.lastSeen = new Date(this.$props.device['last_seen']);
      const now = new Date();

      // Get the difference in milliseconds
      const diffInMs = now - this.lastSeen;

      // Convert the difference into total seconds
      const totalSeconds = Math.floor(diffInMs / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Format the streak as HH:mm:ss
      this.onlineStreak = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    changeItemData(){
      if(this.$route.name === 'monitoring'){
        this.item = 4
      }
    },
    handleItemClick(item) {
      if (item.text === 'Monitoring') {
        this.getLatestTelemetry();
      }
    },
    getLatestTelemetry() {
      // your method implementation
      Monitoring.getFullTelemetryInfo(this.device.uuid);
    },
  },
  data: () => ({
    icons: {
      mdiCheckboxMarkedCircle: mdiCheckboxMarkedCircle,
      mdiCancel: mdiCancel
    },
    onlineStreak: null,
    lastSeen: null,
    item: 0,
    items: [
      {text: 'Basic'},
      {text: 'System Specs'},
      {text: 'Installation Info'},
      {text: 'Services'},
      {text: 'Monitoring'},
    ],
  }),
};
</script>

<style scoped>

</style>
