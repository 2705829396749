<template>
  <div>
    <v-card elevation="0" class="mb-1">
      <v-row class="d-flex justify-left align-center px-4">
        <v-col cols="auto">
          <v-card-subtitle>{{ `Custom Field ${localField.index}` }}</v-card-subtitle> <!-- Bind index here -->
        </v-col>
        <v-col cols="auto">
          <v-switch v-model="localField.enabled" @change="emitUpdate" plain /> <!-- Bind enabled switch -->
        </v-col>
        <v-col cols="auto">
          <v-text-field :disabled="!localField.enabled" v-model="localField.alias" @input="emitUpdate" class="mt-2" outlined dense hide-details /> <!-- Bind alias -->
        </v-col>

        <!-- Radio group to handle required, not required, and unique states -->
        <v-radio-group v-model="radioSelection" row :disabled="!localField.enabled"
        >
          <v-col cols="auto">
            <v-radio :label="$t('assets.custom_fields.radio.notRequired')" :value="null" /> <!-- Not required -->
          </v-col>
          <v-col cols="auto">
            <v-radio :label="$t('assets.custom_fields.radio.required')" :value="true" /> <!-- Required -->
          </v-col>
          <v-col cols="auto">
            <v-radio :label="$t('assets.custom_fields.radio.requiredUnique')" :value="'unique'" /> <!-- Required and Unique -->
          </v-col>
        </v-radio-group>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["field"], // Prop passed from parent
  data() {
    return {
      localField: { ...this.field }, // Local copy of the field prop
    };
  },
  computed: {
    // Computed property to determine which radio button is selected
    radioSelection: {
      get() {
        if (this.localField.unique) {
          return 'unique'; // If unique, select 'unique'
        } else if (this.localField.required) {
          return true; // If required but not unique
        } else {
          return null; // Neither required nor unique
        }
      },
      set(value) {
        if (value === 'unique') {
          this.localField.unique = true;
          this.localField.required = true; // Set both required and unique
        } else if (value === true) {
          this.localField.unique = false;
          this.localField.required = true; // Set required only
        } else {
          this.localField.unique = false;
          this.localField.required = false; // Neither required nor unique
        }
        this.emitUpdate(value); // Emit updated values
      }
    }
  },
  methods: {

    emitUpdate() {
      this.$nextTick(() => {
        this.$emit('update-field', this.localField.index, { ...this.localField });
    });
}

  },
  watch: {
    // If the prop changes, update the local copy
    field: {
      handler(newField) {
        this.localField = JSON.parse(JSON.stringify(newField));
      },
      deep: true,
    }

  },
};
</script>

<style scoped>
.v-textfield-center {
  align-self: center;
}
</style>

