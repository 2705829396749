<template>
  <v-card class="account-modal" v-click-outside="toggleAccountModal" style="z-index: 999;">
    <v-card-title class="pa-4 d-flex justify-space-between align-start">
      <div class="d-flex">
        <img src="../../assets/avatar.png" class="avatar" alt="avatar"/>
        <div class="d-flex flex-column">
          <span class="subtitle-2 ml-2">{{ user.display_name }}</span>
          <span class="caption email ml-2">{{ user.email }}</span>
        </div>
      </div>
      <div>
        <v-btn icon>
          <v-icon size="24">{{ icons.mdiCog }}</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="scrollable-content">
      <v-text-field class="fixed-search-bar" outlined dense placeholder="Search" :append-icon="icons.mdiMagnify" v-model="searchQuery"></v-text-field>
      <div class="scrollable-list" :style="{ height: `${Math.min(filteredSpaces.length + 1, maxVisibleItems) * itemHeight }px` }">
        <v-radio-group v-model="selectedSpace" class="ma-0 pa-0">
          <div v-for="space in extendedFilteredSpaces" :key="space.space_uuid" class="d-flex my-1 align-center justify-space-between">
            <div class="d-flex mb-2">
              <v-avatar class="white--text font-weight-bold space-avatar" color="primary" size="32">
                {{ space.space_name[0].toUpperCase() }}
              </v-avatar>
              <div class="d-flex flex-column ml-2 mt-1" style="word-break: break-all;">
                <span class="subtitle-2 text-secondary">{{ space.space_name + ` (${space.space_type} - ${space.space_datacenter})`}}</span>
              </div>
            </div>
            <v-radio :ripple="false" class="mb-4 custom-radio" :value="space.space_uuid" :on-icon="icons.mdiCheckCircle"></v-radio>
          </div>
        </v-radio-group>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="fixed-sign-out">
      <v-btn @click="logout" class="text-capitalize subtitle-2 font-weight-regular sign-out-btn my-0 py-1" outlined depressed>Sign out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mdiCheckCircle, mdiCog, mdiMagnify } from "@mdi/js";

export default {
  name: "AccountModal",
  data: () => ({
    searchQuery: '',
    selectedSpace: null,
    icons: {
      mdiCog: mdiCog,
      mdiMagnify: mdiMagnify,
      mdiCheckCircle: mdiCheckCircle
    },
    itemHeight: 64, // Adjust based on your item height
    maxVisibleItems: 10 // Adjust based on your maximum visible items
  }),
  computed: {
    ...mapGetters({
      user: "accounts/getUser",
      currentSpace: "global/getSpace"
    }),
    filteredSpaces() {
    const query = this.searchQuery.toLowerCase();
    return this.user.spaces.filter(space =>
      space.space_name.toLowerCase().includes(query) ||
      space.space_type.toLowerCase().includes(query)
    );
  },
  extendedFilteredSpaces() {
    // Filter spaces based on the search query.
    let filteredSpaces = this.filteredSpaces;

    // Always ensure the current space is added to the top if search is cleared.
    const currentSpaceIndex = this.user.spaces.findIndex(space => space.space_uuid === this.currentSpace);
    const currentSpaceObject = this.user.spaces[currentSpaceIndex];

    // If search query is not empty, decide how to handle the selected and current spaces.
    if (this.searchQuery !== '') {
      const selectedIndex = filteredSpaces.findIndex(space => space.space_uuid === this.selectedSpace);

      if (selectedIndex > -1) {
        // Move the selected space to the front of the array if found in the filtered list.
        const [selectedItem] = filteredSpaces.splice(selectedIndex, 1);
        filteredSpaces.unshift(selectedItem);
      } else if (this.selectedSpace) {
        // If the selected space is not in the filtered results, find it and conditionally add it.
        const selectedSpaceObj = this.user.spaces.find(space => space.space_uuid === this.selectedSpace);
        if (selectedSpaceObj) {
          filteredSpaces.unshift(selectedSpaceObj);
        }
      }
    } else {
      // When the search is cleared, make sure to bring the current space back to the top.
      if (currentSpaceObject && currentSpaceIndex > -1) {
        filteredSpaces.unshift(currentSpaceObject);
      }
    }

    // Remove duplicates that might occur by adding the current or selected space.
    return filteredSpaces.filter((space, index, self) =>
      index === self.findIndex(s => s.space_uuid === space.space_uuid)
    );
  }
  },
  methods: {
    ...mapActions({
      setSpace: "global/setSpace",
      toggleAccountModal: "accounts/toggleAccountModal"
    }),
    logout() {
      this.$store.dispatch("iam/logoutUser", this.user.spaces[0].account_uuid);
    }
  },
  watch: {
    currentSpace(newVal) {
      this.selectedSpace = newVal;
    },
    selectedSpace(newVal) {
      this.setSpace(newVal).then(()=>{
        localStorage.removeItem('checkLocations');
        localStorage.removeItem('checkDeviceTags');
        localStorage.removeItem('checkLocationType');
        localStorage.removeItem('checkCountry');
        localStorage.removeItem('checkLocationTags');
      })
    }
  },
  mounted() {
    this.selectedSpace = this.currentSpace;
  }
}
</script>

<style scoped lang="scss">
.account-modal {
  width: 336px;
  position: absolute;
  right: 0px;
  top: 8px;
  z-index: 9999;

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px rgba(34, 34, 34, 0.2);
  }

  .email {
    color: var(--v-blackGray-base);
  }

  .sign-out-btn {
    font-size: 14px !important;
    width: 80px !important;
    height: 24px !important;
  }

  .scrollable-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .fixed-search-bar {
    position: sticky;
    top: 0;
    z-index: 1;
   
  }

  .scrollable-list {
    flex-grow: 1;
    overflow-y: auto;
  }

  .fixed-sign-out {
    position: sticky;
    bottom: 0;
    z-index: 1;

  }
}

.space-avatar {
  border-radius: 4px;
  border: solid 1px rgba(34, 34, 34, 0.2);
}

.custom-radio .v-input--selection-controls__ripple {
  background-color: transparent !important;
  border-color: transparent !important;
}

.custom-radio .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}

.custom-radio .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple::before {
  border-color: var(--v-primary-base) !important;
}

.custom-radio .v-input--selection-controls__input:hover + .v-input--selection-controls__ripple {
  background-color: var(--v-primary-base) !important;
}
</style>
